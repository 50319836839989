<template>
    <div class="row  mt-5 ">
        <form class="col-lg-6 " @submit.prevent="onSubmit">
            <div class="form-group">
                <label for="petrol">Received Petrol Qty</label>
                <input type="number" class="form-control" id="petrol" v-model="ReceivedPetrolQty">
            </div>
            <div class="form-group">
                <label for="diesel">Received Diesel Qty</label>
                <input type="number" class="form-control" id="diesel" v-model="ReceivedDieselQty">
            </div>
            <div class="form-group">
                <label for="pump">Pump</label>
                <select class="form-control" id="pump" v-model="pumpId">
                    <option v-for="pump in allPumps" :key="pump.PumpID" :value="pump.PumpID">{{ pump.PumpName }}</option>
                </select>
            </div>
            <button type="submit" class="btn btn-primary mt-2" @click="saveData()">Submit</button>
        </form>
    </div>
</template>
<script>

import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
import { InventoryMixin } from '@/mixins/InventoryMixin.js';
export default {
    mixins: [ PetrolPumpMixin, InventoryMixin],
    data() {
        return {
            ReceivedPetrolQty: null,
            ReceivedDieselQty: null,
            pumpId: null
        };
    },
    mounted() {
        document.title = 'Inventory';
    },
    methods: {
        async saveData() {
            const lastQty = await this.lastEntryInventoryData(this.pumpId);
            let data = {
                PumpID: this.pumpId,
                ReceivedPetrolQty: this.ReceivedPetrolQty,
                ReceivedDieselQty: this.ReceivedDieselQty,
                ClosingPetrolQty: parseFloat(this.ReceivedPetrolQty) + parseFloat(lastQty.ClosingPetrolQty),
                ClosingDieselQty: parseFloat(this.ReceivedDieselQty) + parseFloat(lastQty.ClosingDieselQty),
            }
            this.submitInventoryData(data);
        }
    }
  
}
</script>