<template>
    <h2>Users List</h2>

    <ul>
        <li v-for="user in users" :key="user.UserID">{{ user.Username }}</li>
    </ul>
</template>
<script>

export default {
    data() {
        return {
            users: [],
        };
    },
    created() {
        
        this.axios.get(`${this.API_URL}get_table_data.php`, {
            params: {
                tableName: 'users'
            }
        })
        .then((response) => {
            this.users = response.data;
        })
        .catch((error) => {
            console.error('Error fetching users:', error);
        });
    },
};
</script>