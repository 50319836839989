<template>
    <div>
        <h2 class="my-3">Add New Petrol Pump</h2>
        <form @submit.prevent="submitData" class="row my-3">
            <div class="col-md-6">

                <div class="input-group mb-1">
                    <span class="input-group-text">Pump Name:</span>
                    <input class="form-control" aria-label="Pump Name" type="text" id="pumpName" v-model="formData.pumpName"
                        required>
                </div>

                <div class="input-group mb-1">
                    <span class="input-group-text">Location:</span>
                    <input class="form-control" aria-label="Location" type="text" id="location" v-model="formData.location"
                        required>
                </div>

                <div class="input-group mb-1">
                    <span class="input-group-text">Petrol Points:</span>
                    <input class="form-control" aria-label="Petrol Points" type="number" id="petrolPoints"
                        v-model="formData.petrolPoints" required>
                </div>

                <div class="input-group mb-1">
                    <span class="input-group-text">Diesel Points:</span>
                    <input class="form-control" aria-label="Diesel Points" type="number" id="dieselPoints"
                        v-model="formData.dieselPoints" required>
                </div>
                <button class="mt-4 btn btn-primary" type="submit">Save Petrol Pump</button>
            </div>
        </form>
    </div>

</template>
  
<script>
import { toastMixin } from '@/mixins/toastMixin.js';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
export default {
    mixins: [toastMixin, PetrolPumpMixin],
    data() {
        return {
            formData: {
                pumpName: '',
                location: '',
                petrolPoints: null,
                dieselPoints: null
            },
        };
    },
    methods: {
        submitData() {
            const apiUrl = `${this.API_URL}add_pump.php`; // Replace with your API endpoint
            const requestData = {
                tableName: 'petrolpumps',
                data: this.formData,
            };

            this.axios.post(apiUrl, requestData).then((response) => {
                console.log(response.data);
                
                this.successToast();
                setTimeout(() => {
                    this.$router.push('/petrol-pumps');
                }, 2200);
                
            }).catch((error) => {
                console.error(error);
            });
        },
    },
};
</script>
  