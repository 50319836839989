// router/index.js
import { createRouter, createWebHistory } from "vue-router";

import LoginComponent from "@/pages/auth/LoginComponent.vue";
import PetrolPumpsList from "@/pages/petrolpumps/PetrolPumpsList.vue";
import PetrolPumpDetail from "@/pages/petrolpumps/PetrolPumpDetail.vue";

import UsersListComponentVue from "@/pages/users/UsersListComponent.vue";

import NotFound from "@/pages/NotFound.vue"
import PricingComponent from "@/pages/PricingComponent.vue"
import DashboardComponentVue from "@/pages/DashboardComponent.vue";
import InventoryComponent from "@/pages/InventoryComponent.vue";
import addNewPumpVue from "@/pages/petrolpumps/addNewPump.vue";
import PetrolPumpReportVue from "@/pages/petrolpumps/PetrolPumpReport.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", redirect: '/dashboard', name:'Default', meta: { requiresAuth: true } },
        { path: "/dashboard", component: DashboardComponentVue, name: 'DashboardComponentVue', meta: { requiresAuth: true } },
        { path: "/inventory", component: InventoryComponent, name: 'InventoryComponent', meta: { requiresAuth: true } },
        { path: "/pricings", component: PricingComponent, name: 'PricingComponent', meta: { requiresAuth: true } },
        { path: "/users", component: UsersListComponentVue, name: 'UsersListComponentVue', meta: { requiresAuth: true } },
        { path: "/add-petrol-pumps", component: addNewPumpVue, name:'addNewPumpVue', meta: { requiresAuth: true } },
        { path: "/petrol-pumps", component: PetrolPumpsList, name:'PetrolPumpsList', meta: { requiresAuth: true } },
        { path: "/petrol-pump/:id", component: PetrolPumpDetail, name:'PetrolPumpDetail', meta: { requiresAuth: true } },
        { path: "/petrol-pump-reports/:id", component: PetrolPumpReportVue, name:'PetrolPumpReport', meta: { requiresAuth: true } },
        
        { path: "/login", component: LoginComponent, name:'login' },
        { path: "/:notFound(.*)", component: NotFound },
    ],
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active', 
});
router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('userLoggedIn'); // Check your session here
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next('/login'); // Redirect to the login page if not authenticated
    } else {
        next();
    }
});

export default router;
