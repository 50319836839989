<template>
    <div>
        <div class="row align-items-center">
            <div class="col-lg-10 p-2">
                <h2> Petrol Pumps List </h2>
            </div>
            <div class="col-lg-2">
                <span class="btn btn-primary">
                    <router-link to="/add-petrol-pumps" class="dropdown-item">Add Petrol Pumps</router-link>
                </span>
            </div>
        </div>
    </div>

    <table class="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Pump Name</th>
                <th scope="col">Location</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(petrolpump, index) in petrolpumps" :key="petrolpump.PumpID">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ petrolpump.PumpName }}</td>
                <td>{{ petrolpump.Location }}</td>
                <td>
                    <div class="btn btn-primary btn-container">
                        <router-link class="text-white text-decoration-none"
                            :to="{ name: 'PetrolPumpDetail', params: { id: petrolpump.PumpID } }"> Go to Details
                        </router-link>
                    </div>
                    <div class="btn btn-primary ml-4">
                        <router-link class="text-white text-decoration-none" :to="{ name: 'PetrolPumpReport', params: { id: petrolpump.PumpID } }"> Reports </router-link>
                    </div>
                </td>
            </tr>

        </tbody>
    </table>
</template>
<style>
.btn-container{
    margin-right: 15px;
    a{
        text-decoration: none;
    }
}
</style>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            petrolpumps: [],
        };
    },
    created() {
        axios.get(`${this.API_URL}get_table_data.php`, {
            params: {
                tableName: 'petrolpumps'
            }
        }).then((response) => {
            this.petrolpumps = response.data;
            console.log(response.data);
        }).catch((error) => {
            console.error('Error fetching Petrol Pumps:', error);
        });
    },
};
</script>