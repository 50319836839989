<template>
    <div>
        <section>
            <p class="m-0 py-2">Petrol Price: {{ petrolPriceToday }} - Diesel Price: {{ dieselPriceToday }}</p>
            <h2 class="text-left mb-3 bg-secondary text-white rounded p-2">{{ pumpDetail.PumpName }} - {{ pumpDetail.Location
            }}</h2>
            <div class="row">
                <div class="col-lg-5">
                    <h4>Petrol Points</h4>
                    <form class="form-group" @submit.prevent="onSubmit">
                        <div v-for="point in petrolPoints" :key="point.PointID" class="mb-3">
                            <p class="text-dark p-0 m-0">Point Id: {{ point.PointID }}</p>
                            <b>Previous Reading: {{ point.lastReading }}</b>
                            <div class="input-group mb-1">
                                <span class="input-group-text">Today Reading:</span>
                                <input type="number" aria-label="First name" class="form-control" v-model="point.Reading"
                                    @keyup="updatePetrolPointReading(point.PointID, point.Reading)">
                            </div>
                            <b>Sold Today: {{ point.TodayCount }}</b>
                            <hr>
                        </div>
                    </form>
                </div>

                <div class="col-lg-5">
                    <h4>Diesel Points</h4>
                    <form class="form-group" @submit.prevent="onSubmit">
                        <div v-for="point in dieselPoints" :key="point.PointID" class="mb-3">
                            <p class="text-dark p-0 m-0">Point Id: {{ point.PointID }}</p>
                            <b>Previous Reading: {{ point.lastReading }}</b>
                            <div class="input-group mb-1">
                                <span class="input-group-text">Today Reading:</span>
                                <input type="number" aria-label="First name" class="form-control" v-model="point.Reading"
                                    @keyup="updateDieselPointReading(point.PointID, point.Reading)">
                            </div>
                            <b>Sold Today: {{ point.TodayCount }}</b>
                            <hr>
                        </div>
                    </form>
                </div>

                <div class="col-lg-2 bg-secondary p-3 rounded-3 text-white">
                    <div class="row">
                        <div class="col mb-3">
                            <h5 class="m-0">Petrol Readings:</h5>
                            <p class="m-0">Readings: {{ PetrolSold }}</p>
                            <p class="m-0">Price: {{ PetrolSold * petrolPriceToday }}</p>
                        </div>

                        <div class="col">
                            <h5 class="m-0">Diesel Readings:</h5>
                            <p class="m-0">Readings: {{ DieselSold }}</p>
                            <p class="m-0">Price: {{ DieselSold * dieselPriceToday }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </section>

        <div class="row">
            <div class="col-lg-6">
                <h4>Expenses</h4>
                <form class="form-group" @submit.prevent="onSubmit">
                    <div class="input-group mb-1">
                        <span class="input-group-text">Staff Expense</span>
                        <input type="number" aria-label="First name" v-model.number="StaffExpense" class="form-control">
                    </div>
                    <div class="input-group mb-1">
                        <span class="input-group-text">Daily Expense</span>
                        <input type="number" aria-label="First name" v-model.number="DailyExpense" class="form-control">
                    </div>

                </form>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-2 bg-secondary p-3 rounded-3 text-white">
                <div class="row">
                    <h5>Subtotal: {{ totalSales }}</h5>
                    <h5>Expenses: {{ calTotalExpenses }}</h5>
                    <h4>Total: {{ allTotalCash }}</h4>
                </div>
            </div>
        </div>

        <button class="btn btn-primary" @click="submitData()">Save Report</button>

    </div>
</template>

<script>

import { toastMixin } from '@/mixins/toastMixin.js';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
import { InventoryMixin } from '@/mixins/InventoryMixin.js';
export default {
    mixins: [toastMixin, PetrolPumpMixin, InventoryMixin],
    data() {
        return {
            pumpId: null,
            petrolpumps: [],
            dieselPoints: [],
            petrolPoints: [],
            petrolReading: 0,
            dieselReading: 0,
            petrolPriceToday: 0,
            dieselPriceToday: 0,

            DieselSold: 0,
            PetrolSold: 0,
            MobilOilSold: 0,
            StaffExpense: 0,
            DailyExpense: 0,
            TotalExpenses: 0,
        };
    },
    mounted() {
        this.fetchPriceData();
        this.fetchPumpData();
    },

    created() {
        this.pumpId = this.$route.params.id;

        this.axios.get(`${this.API_URL}get_pump_details.php`, {
            params: {
                PumpID: this.pumpId
            }
        }).then((response) => {
            const dataToMap = Array.isArray(response.data) ? response.data : [];
            this.petrolpumps = dataToMap.map(point => ({
                ...point,
                Reading: 0,
                TodayCount: 0,
            }));
            this.dieselPoints = this.petrolpumps.filter(item => item.PointType === 'Diesel');
            this.petrolPoints = this.petrolpumps.filter(item => item.PointType === 'Petrol');
        }).catch((error) => {
            console.error('Error fetching Petrol Pumps:', error);
        });
    },
    methods: {
        updateDieselPointReading(PointID, Reading) {
            const pointToUpdate = this.dieselPoints.find(point => point.PointID === PointID);
            if (pointToUpdate) {
                const todayCount = Reading - pointToUpdate.lastReading;
                pointToUpdate.TodayCount = Math.max(todayCount, 0);
            }
            this.sumReadings();
        },
        updatePetrolPointReading(PointID, Reading) {
            const pointToUpdate = this.petrolPoints.find(point => point.PointID === PointID);
            if (pointToUpdate) {
                const todayCount = Reading - pointToUpdate.lastReading;
                pointToUpdate.TodayCount = Math.max(todayCount, 0);
            }
            this.sumReadings();
        },

        submitData() {
            const apiUrl = `${this.API_URL}insert.php`;
            const requestData = {
                tableName: 'dailyreports',
                data: {
                    PumpID: this.pumpId,
                    DieselSold: this.DieselSold,
                    PetrolSold: this.PetrolSold,
                    MobilOilSold: this.MobilOilSold,
                    StaffExpense: this.StaffExpense,
                    DailyExpense: this.DailyExpense,
                    PetrolPrice: this.petrolPriceToday,
                    DieselPrice: this.dieselPriceToday,
                }
            };
            this.axios.post(apiUrl, requestData).then((response) => {
                console.log(response.data);
                this.updateInventory();
                // this.successToast();
            }).catch((error) => {
                console.error(error);
            });
        },
        sumReadings() {

            this.PetrolSold = this.petrolPoints.reduce((sum, obj) => {
                if (obj.TodayCount !== undefined) {
                    return Math.round((sum + Math.max(obj.TodayCount, 0)) * 100) / 100;
                }
                return sum;
            }, 0);

            this.DieselSold = this.dieselPoints.reduce((sum, obj) => {
                if (obj.TodayCount !== undefined) {
                    return Math.round((sum + Math.max(obj.TodayCount, 0)) * 100) / 100;
                }
                return sum;
            }, 0);
        },
        updateTotalExpenses() {
            this.TotalExpenses = this.StaffExpense + this.DailyExpense;
        },
        async updateInventory(){
            const lastQty = await this.lastEntryInventoryData(this.pumpId);
            let data = {
                PumpID: this.pumpId,
                ReceivedPetrolQty: 0,
                ReceivedDieselQty: 0,
                ClosingPetrolQty: parseFloat(lastQty.ClosingPetrolQty) - parseFloat(this.PetrolSold),
                ClosingDieselQty: parseFloat(lastQty.ClosingDieselQty) - parseFloat(this.DieselSold),
            }
            this.submitInventoryData(data);
        }
    },
    computed: {
        calTotalExpenses() {
            return this.StaffExpense + this.DailyExpense
        },
        totalSales() {
            return (this.PetrolSold * this.petrolPriceToday) + (this.DieselSold * this.dieselPriceToday);
        },
        allTotalCash() {
            return this.totalSales - this.calTotalExpenses;
        }

    },
    watch: {
        StaffExpense: "updateTotalExpenses",
        DailyExpense: "updateTotalExpenses"
    },
};
</script>