import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import 'vue3-toastify/dist/index.css';
import { createApp } from 'vue';

import router from './router.js';
import App from './App.vue';

const app = createApp(App);
app.use(router);
// app.config.globalProperties.API_URL = 'http://localhost/Petrozal/src/api/';
app.config.globalProperties.API_URL = 'https://petrozal.amindhodha.com/api/';
/* eslint-disable no-unused-vars */
import axios from 'axios';
app.config.globalProperties.axios = axios;

import { toastMixin } from '@/mixins/toastMixin.js';
app.config.globalProperties.toast = toastMixin;


app.config.globalProperties.loggedIn = false;




app.mount('#app');
