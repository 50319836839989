<template>
    <div class="container">
        <header class="d-flex flex-wrap justify-content-center py-3 border-bottom">
            <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <svg class="bi me-2" width="40" height="32">
                    <use xlink:href="#bootstrap" />
                </svg>
                <!-- <span class="fs-4">CBAC</span> -->
                <img src="../../assets/Dev.png" alt="" height="50">
            </a>

            <ul class="nav nav-pills">
                <li class="nav-item">
                    <router-link to="/dashboard" class="nav-link" exact>Dashboard</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/inventory" class="nav-link" exact>Inventory</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/pricings" class="nav-link" exact>Pricing</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/petrol-pumps" class="nav-link" exact>Petrol Pumps</router-link>
                    <!-- <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Petrol Pumps
                    </button> -->
                    <!-- <div class="dropdown-menu" aria-labelledby="petrolPumpsDropdown">
                        <router-link to="/petrol-pumps" class="dropdown-item">All Petrol Pumps</router-link>
                        <router-link to="/add-petrol-pumps" class="dropdown-item">Add Petrol Pumps</router-link>
                    </div> -->
                </li>

                <li class="nav-item">
                    <router-link to="/users" class="nav-link">Users</router-link>
                </li>
                <li class="nav-item">
                    <button class="nav-link" @click="logout">Logout</button>
                </li>
            </ul>

        </header>
    </div>
</template>

<script>
export default {
    methods: {
        logout() {
            localStorage.removeItem('userLoggedIn'); // Clear the session
            this.$router.push('/login'); // Redirect to the login page after logout
        },
    },
};
</script>
  