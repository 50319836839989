<template>
    <section>
        <h3 class="text-left mb-3 bg-secondary text-white rounded p-2">{{ pumpDetail.PumpName }} - {{ pumpDetail.Location
        }}</h3>
    </section>

    <!-- <button @click="successToast()">Test Toast</button> -->
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Report Date</th>
                <th scope="col">Diesel Qt</th>
                <th scope="col">Diesel Pr</th>
                <th scope="col">Petrol Qt</th>
                <th scope="col">Petrol Pr</th>
                <th scope="col">Staff Exp</th>
                <th scope="col">Daily Exp</th>
                <th scope="col">Total Sale</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="report in reportArr" :key="report.ReportID">
                <td scope="row">{{ formatDate(report.ReportDate) }}</td>
                <td>{{ report.DieselSold }}</td>
                <td>{{ report.DieselSold * report.DieselPrice }}</td>
                <td>{{ report.PetrolSold }}</td>
                <td>{{ report.PetrolSold * report.PetrolPrice }}</td>
                <td>{{ report.StaffExpense }}</td>
                <td>{{ report.DailyExpense }}</td>
                <td>{{ (report.DieselPrice + report.PetrolPrice) - (report.StaffExpense - report.DailyExpense) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { toastMixin } from '@/mixins/toastMixin.js';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
export default {
    mixins: [PetrolPumpMixin, toastMixin],
    data() {
        return {
            pumpId: null,
            reportArr: [],
        }
    },
    mounted() {
        this.fetchPumpData();
        this.reports();
    },
    created() {
        this.pumpId = this.$route.params.id;
    },

    methods: {
        reports() {
            this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM dailyreports WHERE PumpID = ${this.pumpId} ORDER BY ReportDate`,
                }
            }).then((response) => {
                this.reportArr = response.data;
            }).catch((error) => {
                this.failedToast();
                console.error('Error fetching Pump Data', error);
            });
        },
        formatDate(dateString) {
            const formattedDate = new Date(dateString).toLocaleDateString('en-GB');
            return formattedDate;
        }
    }
}
</script>@/mixins/toastMixin.js