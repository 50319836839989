<template>
  <the-header  v-if="showHeader"></the-header>
  <div class="container">
    <router-view />

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import TheHeader from './components/layout/TheHeader.vue';

export default {
  name: 'App',
  components: {
    TheHeader
  },
  computed: {
    showHeader() {
      // Check if the current route is the login page
      // console.log(this.$route);
      return this.$route.name !== 'login'; // Assuming the login route name is 'login'
    }
  }
}
</script>

<style>

</style>
