export const PetrolPumpMixin = {
    data() {
        return {
            pumpDetail: [],
            allPumps: [],
        }
    },
    created(){
        this.getAllPumps();
    },
    methods: {
        async fetchPriceData() {
            try {
                const response = await this.axios.get(`${this.API_URL}get_pricings.php`, {
                    params: {
                        PumpID: this.pumpId
                    }
                });
                this.dieselPriceToday = response.data[0].DieselPrice;
                this.petrolPriceToday = response.data[0].PetrolPrice;
                console.log(this.dieselPriceToday, this.petrolPriceToday);
            } catch (error) {
                console.error('Error fetching prices:', error);
            }
        },
        async fetchPumpData() {
            this.axios.get(`${this.API_URL}get_query.php`, {
                params: {
                    query: `SELECT * FROM petrolpumps WHERE PumpID = ${this.pumpId} `,
                }
            }).then((response) => {
                this.pumpDetail = response.data[0];
            }).catch((error) => {
                console.error('Error fetching Pump Data', error);
            });
        },
        async getAllPumps() {
            this.axios.get(`${this.API_URL}get_table_data.php`, {
                params: {
                    tableName: 'petrolpumps'
                }
            }).then((response) => {
                this.allPumps = response.data;
            }).catch((error) => {
                console.error('Error fetching Petrol Pumps:', error);
            });
        }
    }
}