<template>
    <!-- <h2>Pricing</h2> -->
    <!-- <p>Price Date: {{ price.time_stamp }}</p>
    <div class="row">
        <div class="col">
            <h3 class="h3"> Petrol: {{ price.PetrolPrice }}</h3>
        </div>
        <div class="col">
            <h3 class="h3"> Diesel: {{ price.DieselPrice }}</h3>
        </div>
    </div> -->

    <div class="row  mt-5 ">
        <form class="col-lg-6 " @submit.prevent="onSubmit">
            <div class="form-group">
                <label for="petrol">Petrol Price</label>
                <input type="number" class="form-control" id="petrol" v-model="petrol">
            </div>
            <div class="form-group">
                <label for="diesel">Diesel Price</label>
                <input type="number" class="form-control" id="diesel" v-model="diesel">
            </div>
            <div class="form-group">
                <label for="pump">Pump</label>
                <select class="form-control" id="pump" v-model="pump">
                    <option v-for="pump in allPumps" :key="pump.PumpID" :value="pump.PumpID">{{ pump.PumpName }}</option>
                </select>
            </div>
            <button type="submit" class="btn btn-primary mt-2">Submit</button>
        </form>
    </div>

    <section class="points">

    </section>
</template>
<script>
import { toastMixin } from '@/mixins/toastMixin.js';
import { PetrolPumpMixin } from '@/mixins/PetrolPumpMixin.js';
export default {
    mixins: [toastMixin, PetrolPumpMixin],
    data() {
        return {
            petrol: null,
            diesel: null,
            pump: null,
            price: [],
        };
    },
    mounted() {
        // Set the title when the component is mounted
        document.title = 'Pricings';
    },
    created() {
        this.axios.get('http://localhost/Petrozal/src/api/get_pricings.php').then((response) => {
            this.price = response.data[0];
        }).catch((error) => {
            console.error('Error fetching users:', error);
        });
    },

    methods: {
        onSubmit() {
            
            const data = {
                tableName: 'pricehistory',
                data: {
                    PetrolPrice: this.petrol,
                    DieselPrice: this.diesel,
                    PumpID: this.pump,
                },
            };

            this.axios.post(`${this.API_URL}insert.php`, data)
                .then(response => {
                    console.log(response.data);
                    this.successToast();
                    this.petrol = null;
                    this.diesel = null;
                })
                .catch(error => {
                    console.error(error);
                });
        },
     
    }
}
</script>