<template>
    <div class="wrapper">


        <section>
            <div class="leftPanel">
                <h1 class="welcomeTxt">WELCOME</h1>
                <img class="authSideImg"
                    src="https://raw.githubusercontent.com/geethakash/modern-login-page/80b336d976722c2d61f3ee1c6fa21ca73d25ab1f/img/login-authentication.svg"
                    alt="Login Authentication">
                <div class="custom-shape-divider-bottom-1629894577">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                        preserveAspectRatio="none">
                        <path d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
                            class="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <div class="rightPanel">
                <form class="loginForm">
                    <h2 class="loginFormH2">Log In</h2>
                    <input class="txtInput" type="text" name="username" id="username" v-model="username"
                        placeholder="Username">
                    <input class="txtInput" type="password" name="username" id="password" v-model="password"
                        placeholder="Password">
                    <input class="btnLogin" value="Login" @click="login()">
                </form>
            </div>

        </section>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            username: '',
            password: ''
        };
    },
    mounted() {
        document.title = 'Login | Petrozal';
    },
    methods: {
        login() {
            const formData = new FormData();
            formData.append('username', this.username);
            formData.append('password', this.password);

            this.axios.post(`${this.API_URL}login.php`, formData)
                .then(response => {
                    // Handle the successful login response here
                    console.log(response.data); // You can log the response for debugging
                    // You might want to set some user state or navigate to another page upon successful login
                    if (response.data.status == 1) {
                        localStorage.setItem('userLoggedIn', true);
                        this.$router.push('/dashboard');
                    }else{
                        location.reload();
                    }
                })
                .catch(error => {
                    // Handle login error
                    console.error(error);
                    // You can display an error message to the user here
                });
        }
    }
};
</script>
  
<style scoped>
.wrapper {
    background-color: #12181b;
    color: white;
    margin: 0px;
    border: 0;
    overflow: hidden;
}

.leftPanel {
    background-color: #2a2e35;
    float: left;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rightPanel {
    float: right;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcomeTxt {
    color: white;
    margin-top: -130px;
    font-size: 50px;
    letter-spacing: 20px;
}

.authSideImg {
    width: 50%;
}


/*css for form*/
.loginForm {
    padding: 0px 30px 30px 30px;
    background-color: #2a2e35;
    border-radius: 5px;
    width: 50%;
    height: 50vh;
    /* margin: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.loginFormH2 {
    text-align: center;
    margin-top: 0px;
    font-size: 35px;
}

.inputInDiv {
    margin: 10px;
    display: flex;
    justify-content: center;


}

.txtInput {
    font-family: inherit;
    width: 90%;
    height: 25px;
    margin: 10px 0px;
    padding-right: 10px;
    font-size: 1.1rem;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: 0px;
    border-bottom: 2px solid #7d2dc9;
    transition: 0.5s;
    text-align: right;
}

.txtInput:focus {
    border-bottom: 2px solid #11998e;
    outline: none;


}

.txtInput:focus :placeholder-shown {
    display: none;
}

.btnLogin {
    color: white;
    width: 80%;
    height: 40px;
    margin-top: 20px;
    font-family: inherit;
    font-size: 1rem;
    background-color: #8d26ed;
    border: 0;
    float: right;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.5s;
}

.btnLogin:hover {
    background-color: #11998e;

}

.forgotlink {
    width: 100%;
    font-size: 10px;
    text-decoration: none;
    margin-top: -5px;
    padding-right: 30px;
    color: white;
    text-align: right;
}

.forgotlink:hover {
    text-decoration: underline;
}

.haveacclbl {
    font-size: 15px;
}

.reglink {
    color: #11998e;

}



/*for bottom svg*/
.custom-shape-divider-bottom-1629894577 {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -15px;
    width: 50%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1629894577 svg {
    position: relative;
    display: block;
    width: calc(168% + 1.3px);
    height: 500px;
}

.custom-shape-divider-bottom-1629894577 .shape-fill {
    fill: #7d2dc9;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
    .custom-shape-divider-bottom-1629894577 svg {
        width: calc(160% + 1.3px);
        height: 306px;
    }
}

/** For mobile devices **/
@media (max-width: 767px) {
    .custom-shape-divider-bottom-1629894577 svg {
        width: calc(252% + 1.3px);
        height: 500px;
    }
}
</style>